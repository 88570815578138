import Vue from 'vue'
import {
    popup,
    picker,
    uploader,
    dialog,
    loading,
    overlay,
    RadioGroup,
    Radio,
    DatetimePicker,
    Cell,
    CellGroup,
    Icon,
    Button,
    toast,
} from "vant";

Vue.use(popup)
Vue.use(picker)
Vue.use(uploader)
Vue.use(dialog)
Vue.use(loading)
Vue.use(overlay)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(DatetimePicker)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Icon)
Vue.use(Button)
Vue.use(toast)
export default {
    popup,
    picker,
    uploader,
    dialog,
    loading,
    overlay,
    RadioGroup,
    Radio,
    DatetimePicker,
    Cell,
    CellGroup,
    Icon,
    Button,
    toast,
}